import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import NewsColumn from "./NewsColumn";


const NewsGrid = ({
  heading
}) => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              template: {
                eq: "news"
              }
            }
          }
          sort: {
            fields: [frontmatter___date]
            order: DESC
          }
        ) {
          edges {
            node {
              frontmatter {
                title
                author
                category
                link
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 70) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                altText
              }
            }
          }
        }
      }
    `
  );

  const newsColumns = allMarkdownRemark.edges.map(node => {
    return (
      <NewsColumn 
        title={ node.node.frontmatter.title }
        author={ node.node.frontmatter.author }
        category={ node.node.frontmatter.category }
        featuredImage={ node.node.frontmatter.featuredImage.childImageSharp.fluid }
        altText={ node.node.frontmatter.altText }
        pageLink={ node.node.frontmatter.link }
      />
    )
  });
  
  return (
    <section className="container mx-auto my-16">
      { heading &&
        <h2 className="mb-8 px-6 lg:px-28 text-3xl font-thin">{ heading }</h2>
      }
      <div className="lg:grid lg:grid-cols-3 lg:gap-8 px-6 lg:px-28">
        { newsColumns }
      </div>
    </section>
  )
}

export default NewsGrid;