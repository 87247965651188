import React from "react";
import Img from "gatsby-image";


const NewsColumn = ({ 
  title,
  author,
  category,
  featuredImage,
  altText,
  pageLink
}) => {
  return (
    <div className="mb-8 lg:mb-0">
      <a
        href={ pageLink }
        target="_blank"
        rel="noopener noreferrer"
      >
        <Img
          fluid={ featuredImage }
          alt={ altText }
        />
      </a>
      <p className="pt-4 text-sm font-thin">{ category } &mdash; { author }</p>
      <h3 className="pt-2 text-2xl font-thin">
        <span>{ title }</span>
      </h3>
    </div>
  )
}

export default NewsColumn;
