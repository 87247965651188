import React from "react";

import CallToAction from "../../components/CallToAction/CallToAction";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import Layout from "../../components/Layout/Layout";
import NewsGrid from "../../components/Grid/News/NewsGrid";


const NewsPage = () => {
  return (
    <Layout
      title="In The News"
      description="Our lawyers are always fighting for your rights, that's why we're usually featured
      in recent news."
    >
      <Header
        colorScheme="light"
      />

      <Hero
        title="In The News"
        colorScheme="light"
      />

      <NewsGrid />

      <CallToAction />

      <Footer />
    </Layout>
  );
}

export default NewsPage;